import { animated, useScroll } from '@react-spring/web'
import { useCallback, useEffect, useRef, useState } from 'react'
import { twMerge } from 'tailwind-merge'

import {
  useTimelineContext,
  type TimelineEntry as TTimelineEntry,
} from 'src/components/Timeline/TimelineContext'
import { Typography } from 'src/components/Typography'
import { useMediaQuery } from 'src/hooks/window'
import { useViewportCenter } from 'src/hooks/window/useViewportCenter'
import { breakpointMediaShort } from 'src/styles'
import { clamp } from 'src/utils/math/clamp'

interface Props {
  entry: TTimelineEntry
  index: number
  initiallyVisible?: boolean
}

export const TimelineTreeEntry = ({ entry, index, initiallyVisible = false }: Props) => {
  const isLargeAndUp = useMediaQuery(breakpointMediaShort.largeAndUp)
  const { entries, updateActiveIndex, updateActiveImageIndex } = useTimelineContext()
  const { headerHeight, viewportCenter } = useViewportCenter()

  const [visible, setVisible] = useState(initiallyVisible)

  const entryRef = useRef<HTMLDivElement>(null)
  const isVisibleRef = useRef(false)

  const { scrollY } = useScroll()

  const onScroll = useCallback(() => {
    if (!entryRef.current) return
    const elementBottom = (entryRef.current.getBoundingClientRect().bottom ?? 0) - headerHeight
    const elementHeight = entryRef.current.getBoundingClientRect().height ?? 0

    const isAboveVisiblePoint = isLargeAndUp
      ? elementBottom - elementHeight <= viewportCenter
      : elementBottom - elementHeight <= viewportCenter + (window.innerHeight - headerHeight) / 4 // last quater of the screen

    if (isAboveVisiblePoint) {
      if (!isVisibleRef.current) {
        setVisible(true)
        updateActiveImageIndex(entry.imgIndex)
        updateActiveIndex(index)
        isVisibleRef.current = true
        if (index === 1) {
          obe.analytics.track(obe.events.timeline_scrolled, {
            path: window.location.pathname,
          })
        }
      }
    } else {
      if (!initiallyVisible && isVisibleRef.current) {
        setVisible(false)
        updateActiveImageIndex(entry.imgIndex)
        updateActiveIndex(index)
        isVisibleRef.current = false
      }
    }
  }, [
    entry.imgIndex,
    headerHeight,
    index,
    initiallyVisible,
    isLargeAndUp,
    updateActiveImageIndex,
    updateActiveIndex,
    viewportCenter,
  ])

  useEffect(() => {
    window.addEventListener('scroll', onScroll)
    return () => {
      window.removeEventListener('scroll', onScroll)
    }
  }, [onScroll])

  return (
    <animated.div
      key={`timeline-entry-${entry.title}`}
      ref={entryRef}
      className={twMerge(
        `relative flex items-start gap-4 opacity-25 transition-opacity duration-300
        [&:not(:last-of-type)]:pb-12`,
        [visible && 'opacity-100'],
      )}
    >
      {index < entries.length - 1 && (
        <animated.div
          className="absolute left-0 top-2 ml-[7px] w-[1px] min-w-[1px] bg-primary-400"
          style={{
            height: scrollY.to(() => {
              const containerRect = entryRef.current?.getBoundingClientRect()
              const containerTop = (containerRect?.top ?? 0) - headerHeight
              const containerHeight = containerRect?.height ?? 0
              const lineHeight = isLargeAndUp
                ? viewportCenter - containerTop
                : viewportCenter + (window.innerHeight - headerHeight) / 4 - containerTop
              const clampedLineHeight = clamp(lineHeight, 0, containerHeight)
              return `${clampedLineHeight}px`
            }),
          }}
        />
      )}
      <div className="mt-1 aspect-square w-[15px] min-w-[15px] rounded-full bg-primary-400 lg:mt-2.5" />
      <div className="flex flex-col gap-3">
        <Typography.Heading5>{entry.title}</Typography.Heading5>
        <Typography.Body3>{entry.text}</Typography.Body3>
      </div>
    </animated.div>
  )
}
