'use client'

import { Timeline } from 'src/components/Timeline/Timeline'
import { Typography } from 'src/components/Typography'
import { workoutTimeline, workoutTimelineImages } from 'src/views/AboutUs/WomensHistory/timeline'

export const WomensHistory = () => {
  return (
    <Timeline
      images={workoutTimelineImages}
      entries={workoutTimeline}
      ImageComponent={<Timeline.Image />}
      ContentComponent={
        <Timeline.Content
          before={
            <section className="flex flex-col gap-4">
              <Typography.Heading2>A brief history of women&apos;s fitness</Typography.Heading2>
              <Typography.Body2>
                Just 50 years ago, conventional wisdom still held that exercise was potentially
                dangerous for women.
              </Typography.Body2>
            </section>
          }
        />
      }
    />
  )
}
