import { useEffect, useState } from 'react'
import * as Sentry from '@sentry/nextjs'
import { Image } from 'src/components/Display/Image'
import { Video } from './Display/Video/Video'

interface ObservedVideoProps {
  videoSrcSlug: string
  videoClassName?: string
  includeHiRes?: boolean
  disableMuteOption?: boolean
}

export const ObservedVideo = ({
  videoSrcSlug,
  videoClassName,
  includeHiRes = false,
  disableMuteOption,
}: ObservedVideoProps) => {
  const [isMuted, setIsMuted] = useState(true)

  const videoRef = React.useRef<HTMLVideoElement>(null)

  const toggleMute = () => {
    if (videoRef.current?.muted) {
      videoRef.current.muted = !isMuted

      obe.analytics.track(obe.events.landing_page_block_item_pressed, {
        item: 'video-testimonial',
      })
    }
    setIsMuted(!isMuted)
  }

  useEffect(() => {
    const options = {
      rootMargin: '0px',
      threshold: 0,
    }

    const handlePlay = (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          videoRef?.current?.play()?.catch((error) => {
            if (error?.name !== 'NotAllowedError' && error?.name !== 'AbortError') {
              Sentry.captureException(error)
            }
          })
        } else {
          if (videoRef.current) videoRef.current.muted = true
          setIsMuted(true)
          videoRef.current?.pause()
        }
      })
    }

    const observer = new IntersectionObserver(handlePlay, options)
    if (videoRef.current) observer.observe(videoRef.current)
  })

  return (
    <button
      onClick={
        disableMuteOption
          ? () => {
              null
            }
          : toggleMute
      }
      className="md:self-first relative self-center hover:outline-none focus:outline-none "
    >
      <Video
        src={videoSrcSlug}
        includeHiRes={includeHiRes}
        ref={videoRef}
        muted={isMuted}
        loop
        playsInline
        className={`${videoClassName} relative`}
      />

      {!disableMuteOption && (
        <div
          onClick={toggleMute}
          className="absolute left-[-4px] top-[4px] z-[5] h-[78px] w-[78px] cursor-pointer outline-none
            hover:outline-none focus:outline-none md:top-[15px] lg:left-[-4px] lg:top-[4px]"
        >
          {isMuted ? (
            <Image src='/muted-button.png' width={58} height={58} alt='turn on sound' />
          ) : (
            <Image src='/unmuted-button.png' width={58} height={58} alt='mute sound' />
          )}
        </div>
      )}
    </button>
  )
}
