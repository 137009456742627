import { createContext, useContext } from 'react'

export interface TimelineImage {
  src: string
  alt: string
}

export interface TimelineEntry {
  imgIndex: number
  title: string
  text: string
}

interface TimelineState {
  images: TimelineImage[]
  entries: TimelineEntry[]

  activeIndex: number
  activeImageIndex: number
  updateActiveIndex: (index: number) => void
  updateActiveImageIndex: (index: number) => void
}

export const TimelineContext = createContext<TimelineState | null>(null)

export function useTimelineContext() {
  const context = useContext(TimelineContext)
  if (!context) {
    throw new Error(`${useTimelineContext.name} must be used within a TimelineProvider`)
  }
  return context
}
