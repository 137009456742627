export const CarouselDelayInSeconds = {
  NONE: 0,
  THREE: 3000,
  FOUR: 4000,
  SIX: 6000,
} as const

export const CarouselSpeedInMilliseconds = {
  ONE_HUNDRED: 100,
  FIVE_HUNDRED: 500,
  SEVEN_HUNDRED: 700,
  EIGHT_HUNDRED: 800,
  FIFTEEN_HUNDRED: 1500,
  TWO_THOUSAND: 2000,
  TWENTY_EIGHT_HUNDRED: 2800,
} as const
