import type { TimelineEntry, TimelineImage } from 'src/components/Timeline/TimelineContext'

export const workoutTimelineImages = [
  { src: '/assets/about-us/womens-history-timeline/1-1.png', alt: 'womens history timeline image' },
  { src: '/assets/about-us/womens-history-timeline/2.png', alt: 'womens history timeline image' },
  { src: '/assets/about-us/womens-history-timeline/3-1.png', alt: 'womens history timeline image' },
  { src: '/assets/about-us/womens-history-timeline/4.png', alt: 'womens history timeline image' },
] as const satisfies TimelineImage[]

export const workoutTimeline = [
  {
    imgIndex: 0,
    title: '1967',
    text: 'Kathrine Switzer enters the Boston marathon under an alias to obscure her gender. Women weren’t allowed the enter—it was believed they couldn’t physically run more than 2 miles. ',
  },
  {
    imgIndex: 0,
    title: '1972',
    text: 'Women are finally allowed to officially run the Boston marathon. Months later, Title IX is signed!',
  },
  {
    imgIndex: 1,
    title: '1977',
    text: 'Three women invent the first sports bra from two jock straps, sparking the jogging craze. Before this, women didn’t have athletic attire for their bodies.',
  },
  {
    imgIndex: 1,
    title: '1984',
    text: 'Women are officially allowed to run a marathon in the Olympic games for the first time.',
  },
  {
    imgIndex: 2,
    title: '1980s',
    text: 'Aerobics brings more women to fitness. The VHS boon begins with promises of buns of steel and 28-minute abs.',
  },
  {
    imgIndex: 2,
    title: '2010s',
    text: 'Fitness starts becoming a symbol of wealth and status with celebrity trainers, expensive in-person classes, and boutique studios. Beauty standards are strict.',
  },
  {
    imgIndex: 3,
    title: '2018',
    text: 'obé was born to bring the fun back into fitness—empowering women everywhere to have a workout experience that gets them excited to show up.',
  },
] as const satisfies TimelineEntry[]
