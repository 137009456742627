import { useRouter } from 'src/hooks/useRouter'

export const ScrollToTop = ({ children }) => {
  const router = useRouter()

  const ignoredPathnames = {
    '/': true,
    '/profile': true,
    '/profile/class-history': true,
    '/profile/favorites': true,
    '/profile/friends': true,
  }

  const isFriendPath = () =>
    /^\/?(.*)\/(profile|favorites|class-history|friends)\/?$/.test(router?.pathname)

  React.useEffect(() => {
    if (!ignoredPathnames[router?.pathname] && !isFriendPath()) {
      window.scrollTo(0, 0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router?.pathname])

  return children || null
}
