'use client'

import { ReactNode } from 'react'

import { DispatchPageView } from 'src/components/App/DispatchPageView'
import { ExceptionDispatcher } from 'src/components/App/ExceptionDispatcher'
import { SiteFooter } from 'src/components/App/Footer/SiteFooter'
import { LandingHeader } from 'src/components/App/Header/Landing/LandingHeader'
import { ScrollToTop } from 'src/components/ScrollToTop'
import { SignUpContextProvider } from 'src/hooks/useSignUpContext/SignUpContextProvider'
import { useUser } from 'src/hooks/useUser'
import { CouponProvider } from 'src/providers/CouponProvider/CouponProvider'

interface Props {
  transparent?: boolean
  children?: ReactNode
}

export const MarketingLayout = ({ transparent = false, children }: Props) => {
  const userStore = useUser()

  return (
    <ExceptionDispatcher>
      <DispatchPageView>
        <SignUpContextProvider>
          <CouponProvider>
            {!userStore.isLoading && (
              <ScrollToTop>
                {transparent ? (
                  <Container>
                    <LandingHeader />
                    <ContentWrapper>
                      {children}
                      <SiteFooter />
                    </ContentWrapper>
                  </Container>
                ) : (
                  <>
                    <div style={{ minHeight: '100vh' }}>
                      <LandingHeader />
                      <main
                        className="pt-[calc(var(--mobile-header-height)+var(--coupon-banner-height))]
                          lg:pt-[calc(var(--landing-header-height)+var(--coupon-banner-height))]"
                      >
                        {children}
                      </main>
                    </div>
                    <SiteFooter />
                  </>
                )}
              </ScrollToTop>
            )}
          </CouponProvider>
        </SignUpContextProvider>
      </DispatchPageView>
    </ExceptionDispatcher>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 100vh;
`

const ContentWrapper = styled.div`
  width: 100%;
  /* margin-top: var(--site-header-height); */
  max-height: calc(100vh - var(--landing-header-height));
`
