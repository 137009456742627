import type { ReactNode } from 'react'

import { TimelineTree } from 'src/components/Timeline/TimelineTree'

interface Props {
  before?: ReactNode
  after?: ReactNode
}

export const TimelineContent = ({ before, after }: Props) => {
  return (
    <div className="flex flex-col gap-6 !px-4 !pt-4 lg:w-1/2 lg:gap-8 lg:!px-0 lg:pt-0">
      {before}
      <section>
        <TimelineTree />
      </section>
      {after}
    </div>
  )
}
