import { forwardRef, useMemo, type DetailedHTMLProps, type VideoHTMLAttributes } from 'react'

const DEFAULT_TYPES = ['video/mp4', 'video/webm'] as const

type SupportedVideoType = 'video/webm' | 'video/mp4'

interface Props extends DetailedHTMLProps<VideoHTMLAttributes<HTMLVideoElement>, HTMLVideoElement> {
  src: string
  types?: SupportedVideoType[]
  includeHiRes?: boolean
}

export const Video = forwardRef<HTMLVideoElement, Props>(
  ({ src, types = DEFAULT_TYPES, includeHiRes = false, ...props }, ref) => {
    const hiResSources = useMemo(() => {
      if (!includeHiRes) return []
      return types.map((type) => (
        <source
          key={`${type}-hi-res`}
          type={type}
          src={`${src}-hi-res${extensionByType[type]}`}
          media={includeHiRes ? mediaQueries.hiRes : undefined}
        />
      ))
    }, [includeHiRes, src, types])

    const defaultSources = useMemo(() => {
      return types.map((type) => (
        <source
          key={`${type}-default`}
          type={type}
          src={`${src}${extensionByType[type]}`}
          media={includeHiRes ? mediaQueries.default : undefined}
        />
      ))
    }, [includeHiRes, src, types])

    const sources = useMemo(
      () => [...hiResSources, ...defaultSources],
      [hiResSources, defaultSources],
    )

    return (
      <video ref={ref} {...props}>
        {sources.map((source) => (
          <source key={source.key} {...source.props} />
        ))}
      </video>
    )
  },
)

const mediaQueries = {
  default:
    '(max-device-pixel-ratio:1), (-webkit-max-device-pixel-ratio:1), (max--moz-device-pixel-ratio:1), (-o-max-device-pixel-ratio:1)',
  hiRes:
    '(min-device-pixel-ratio:2), (-webkit-min-device-pixel-ratio:2), (min--moz-device-pixel-ratio:2), (-o-min-device-pixel-ratio:2)',
} as const

const extensionByType = {
  'video/mp4': '.mp4',
  'video/webm': '.webm',
} as const satisfies Record<SupportedVideoType, string>
