import { useEffect, useState, type HTMLAttributes } from 'react'
import { useSwiper } from 'swiper/react'
import { twMerge } from 'tailwind-merge'

interface Props extends HTMLAttributes<HTMLDivElement> {
  dots: number
}

export const SwiperNavigationDots = ({ className, dots, ...props }: Props) => {
  const swiper = useSwiper()

  const [swiperState, setSwiperState] = useState({
    activeIndex: 0,
  })

  useEffect(() => {
    swiper.on('slideChange', (swiper) => {
      setSwiperState({ activeIndex: swiper.realIndex })
    })
    return () => swiper.off('slideChange')
  }, [swiper])

  return (
    <div className={twMerge('mt-4 flex justify-center gap-2', className)} {...props}>
      {Array.from({ length: dots }, (_, index) => (
        <div
          key={index}
          className={twMerge(
            'duration-250 h-2 w-2 cursor-pointer rounded-full bg-primary-100 transition-colors',
            [swiperState.activeIndex === index && 'bg-primary-400'],
          )}
          onClick={() => swiper.slideTo(index)}
        />
      ))}
    </div>
  )
}
