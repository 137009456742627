'use client'

import { Image } from 'src/components/Display/Image'
import { ObservedVideo } from 'src/components/ObservedVideo'
import { Typography } from 'src/components/Typography'

const copy = {
  headline: 'Only 6% of sports & exercise science studies focus on women. ',
  body: 'Most exercise guidance we get today is still based on men and their physiology.',
}

export const WomensHealthStudySection = () => {
  return (
    <div className="flex w-full select-none flex-col justify-center gap-12 lg:gap-[100px]">
      <div
        className="relative flex max-w-[950px] flex-1 flex-col justify-center self-center rounded-[20px] lg:mx-[8vw]
          lg:flex-row lg:justify-between lg:gap-[48px]"
      >
        <div
          className="flex w-[90%] max-w-[433px] flex-col self-center rounded-[20px] bg-primary-20 lg:w-[100%]
            lg:max-w-[100000px] lg:flex-row lg:self-start lg:rounded-[20px]"
        >
          <ObservedVideo
            videoSrcSlug='/womens-study'
            videoClassName='rounded-b-[0px] rounded-t-[20px] lg:rounded-l-[20px] lg:rounded-tr-[0px] lg:w-[400px]'
          />
          <div
            className="pointer-events-none flex flex-wrap gap-[12px] self-center rounded-[0] rounded-b-[20px] p-[24px]
              lg:w-[627px] lg:rounded-[20px] lg:rounded-l-[0px] lg:rounded-r-[20px] lg:p-[4px] lg:pl-[48px]
              lg:pr-[40px]"
          >
            <Typography.Heading4>{copy.headline}</Typography.Heading4>
            <Typography.Body1>{copy.body}</Typography.Body1>
          </div>
        </div>
      </div>
      <div className="relative mx-auto max-w-[433px] !px-4 lg:max-w-[950px]">
        <div className="absolute -top-20 right-5 z-10 lg:-top-36 lg:right-0">
          <Image
            src='/assets/home/purple-arrow.png'
            alt='arrow'
            width={140}
            height={140}
            className="size-[80px] -rotate-[30deg] lg:size-[140px]"
          />
        </div>
        <Typography.Display4 className="bg-gradient-1000 bg-clip-text text-center text-transparent">
          At obé, you&apos;re not an afterthought. We do the research for you, centering our
          platform around women and your unique body.
        </Typography.Display4>
      </div>
    </div>
  )
}
