import type { ReactNode } from 'react'

import { TimelineContent } from 'src/components/Timeline/TimelineContent'
import type {
  TimelineEntry,
  TimelineImage as TTimelineImage,
} from 'src/components/Timeline/TimelineContext'
import { TimelineProvider } from 'src/components/Timeline/TimelineProvider'
import { TimelineImage } from 'src/components/Timeline/TimelineImage'

interface Props {
  images: TTimelineImage[]
  entries: TimelineEntry[]
  ImageComponent: ReactNode
  ContentComponent: ReactNode
}

export const Timeline = ({
  images,
  entries,
  ImageComponent = <TimelineImage />,
  ContentComponent = <TimelineContent />,
}: Props) => {
  return (
    <TimelineProvider images={images} entries={entries}>
      <div className="container mx-auto flex flex-col gap-6 lg:flex-row lg:gap-14 ">
        {ImageComponent}
        {ContentComponent}
      </div>
    </TimelineProvider>
  )
}

Timeline.Image = TimelineImage
Timeline.Content = TimelineContent
