import { useCallback, useState, type ReactNode } from 'react'

import {
  TimelineContext,
  type TimelineEntry,
  type TimelineImage,
} from 'src/components/Timeline/TimelineContext'

interface Props {
  images: TimelineImage[]
  entries: TimelineEntry[]
  children: ReactNode
}

export const TimelineProvider = ({ images, entries, children }: Props) => {
  const [activeIndex, setActiveIndex] = useState(0)
  const [activeImageIndex, setActiveImageIndex] = useState(0)

  const updateActiveIndex = useCallback((index: number) => {
    setActiveIndex(index)
  }, [])

  const updateActiveImageIndex = useCallback((index: number) => {
    setActiveImageIndex(index)
  }, [])

  return (
    <TimelineContext.Provider
      value={{
        images,
        entries,
        activeIndex,
        activeImageIndex,
        updateActiveIndex,
        updateActiveImageIndex,
      }}
    >
      {children}
    </TimelineContext.Provider>
  )
}
