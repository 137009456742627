import { animated, useScroll, useTransition } from '@react-spring/web'
import { useRef, type DetailedHTMLProps, type ImgHTMLAttributes } from 'react'
import { twMerge } from 'tailwind-merge'

import { useTimelineContext } from 'src/components/Timeline/TimelineContext'
import { useMediaQuery } from 'src/hooks/window'
import { useViewportCenter } from 'src/hooks/window/useViewportCenter'
import { breakpointMediaShort } from 'src/styles'
import { cloudflareLoader, widths } from 'src/utils/image'
import { clamp } from 'src/utils/math/clamp'

export const TimelineImage = ({
  className,
  ...props
}: Omit<DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>, 'ref'>) => {
  const imageContainerRef = useRef<HTMLDivElement | null>(null)

  const { headerHeight } = useViewportCenter()
  const { images, activeImageIndex } = useTimelineContext()

  const isLargeAndUp = useMediaQuery(breakpointMediaShort.largeAndUp)

  const imageRef = useRef<HTMLImageElement | null>(null)

  const { scrollY } = useScroll()

  const transitions = useTransition(activeImageIndex, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    exitBeforeEnter: true,
    config: {
      duration: 150,
    },
  })

  return (
    <div
      className="sticky left-0 top-[calc(0px+var(--mobile-header-height))] z-[1] flex
        h-[calc(50vh-(var(--mobile-header-height)/2))] w-full justify-center overflow-hidden bg-primary-20
        shadow-[0px_38px_16px_rgba(247,242,255,1)] lg:relative lg:top-auto lg:block lg:h-auto lg:w-1/2
        lg:bg-transparent lg:shadow-none"
      ref={imageContainerRef}
    >
      {transitions((styles, index) => {
        return (
          <animated.img
            ref={imageRef}
            src={cloudflareLoader({
              src: images[index].src,
              width: widths.card,
            })}
            alt={images[index].alt}
            className={twMerge(
              `h-full rounded-2.5xl !px-4 lg:absolute lg:left-0 lg:right-0 lg:top-0 lg:my-auto lg:h-auto lg:w-full
              lg:max-w-[556px]`,
              className,
            )}
            style={{
              ...styles,
              top: isLargeAndUp
                ? scrollY.to(() => {
                    const containerRect = imageContainerRef.current?.getBoundingClientRect()
                    const scrollStart = (containerRect?.top ?? 0) - headerHeight
                    const containerHeight = containerRect?.height ?? 0
                    const imageHeight = imageRef.current?.getBoundingClientRect()?.height ?? 0

                    return `${clamp(
                      (window.innerHeight - headerHeight - imageHeight) / 2 - scrollStart,
                      0,
                      containerHeight - imageHeight,
                    )}px`
                  })
                : undefined,
            }}
            {...props}
          />
        )
      })}
    </div>
  )
}
