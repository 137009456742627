import { usePathname } from 'next/navigation'
import { useMemo } from 'react'

import { useMediaQuery } from 'src/hooks/window/useMediaQuery'
import { breakpointMediaShort } from 'src/styles'

export const useViewportCenter = () => {
  const pathname = usePathname()
  const isLargeAndUp = useMediaQuery(breakpointMediaShort.largeAndUp)

  const headerHeight = useMemo(() => {
    return (
      pathnameLayoutMap[pathname as keyof typeof pathnameLayoutMap][
        isLargeAndUp ? 'desktop' : 'mobile'
      ] ?? layoutHeaderHeights.default
    )
  }, [isLargeAndUp, pathname])

  const viewportCenter = useMemo(() => {
    return (window.innerHeight - headerHeight) / 2
  }, [headerHeight])

  return {
    headerHeight,
    viewportCenter,
  }
}

const layoutHeaderHeights = {
  landing: {
    desktop: 84,
    mobile: 70,
  },
  default: {
    desktop: 84,
    mobile: 62,
  },
} as const

const pathnameLayoutMap = {
  '/': layoutHeaderHeights.landing,
  '/about-us': layoutHeaderHeights.landing,
} as const
