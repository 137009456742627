'use client'

import { useTimelineContext } from 'src/components/Timeline/TimelineContext'
import { TimelineTreeEntry } from 'src/components/Timeline/TimelineTreeEntry'

export const TimelineTree = () => {
  const { entries } = useTimelineContext()

  return (
    <div className="relative flex flex-col">
      {entries.map((entry, index) => (
        <TimelineTreeEntry
          key={`timeline-entry-${entry.title}`}
          entry={entry}
          index={index}
          initiallyVisible={index === 0}
        />
      ))}
    </div>
  )
}
